import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Technologies = () => {
  return (
    <section id="features">
        <div className="container mt-4 pt-4">
        <br></br><h1 className="text-center">Features And Benefits</h1>
            <div className="row mt-4">
    
                <div className="col-lg-12 text-center">
                    <p> Our expert software developers have expertise in all the Platforms needed to deliver comprehensive Solutions for companies across industries.            
                    </p><br></br>
                    <div className="row mt-3">

                    <div className="col-md-4">
                        <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">Increased Efficiency</h4><br/>
                                    <p class="tall">By collaborating with IT specialists, we can streamline processes, automate repetitive tasks, and decrease manual effort, resulting in improved efficiency and productivity.</p>
                                </div>
                           </div>
                           <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">Cost-effective</h4><br/>
                                    <p class="tall">We build cost-effective solutions that will rightly fit your budget, and avoid frivolous & unnecessary expenditures.</p>
                                </div>
                           </div>
                        </div>

                        <div className="col-md-4">
                        <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">Competitive Advantage</h4><br/>
                                    <p class="tall">We can maintain a competitive edge and stay ahead of the curve in the rapidly evolving technological landscape by working with IT experts who stay ahead of industry trends and advancements.</p>
                                </div>
                           </div>
                           <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">Scalable</h4><br/>
                                    <p class="tall">We understand your needs. We’ve worked with small & large clients who are looking for a scalable solution</p>
                                </div>
                           </div>
                        </div>

                        <div className="col-md-4">
                            <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">Improved Technology Solutions</h4><br/>
                                    <p class="tall">With the support of our IT experts, we can enhance business operations and outcomes through customized technology solutions designed to fit your company’s specific requirements.</p>
                                </div>
                           </div>
                           <div class="feature-box">
                                <div class="feature-box-icon">
                                    <i class="icon-group"></i>
                                </div>
                                <div class="feature-box-info">
                                    <h4 class="shorter">User Friendly</h4><br/>
                                    <p class="tall">We understand how the user thinks what he thinks what he looks for, so do we.</p>
                                </div>
                           </div>
                        </div>
         
                    </div>

                </div>
            </div>
        </div>
        <br></br>
    </section>
  )
}

export default Technologies