import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Quotes = () => {
  return (
    <section id="quotes">
           <div className="container mt-4 pt-4">
           <div className='row mt-3'>
                        <p></p><p></p><p></p>
                        <p className='text-center'>
                        <h3>We provide customized solutions that guarantee a high <br></br> level of performance and company sustainability.</h3>
                        </p>
                    </div>
        <br></br>  <br></br>
        </div>
    </section>
  )
}

export default Quotes

