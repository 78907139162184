import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Verify from "../pages/verify.tsx";
import NoPage from "../pages/NoPage";
import Robots from "../pages/Robots.txt"
import Sitemap from "../pages/Sitemap.xml"
import { Outlet, Link } from "react-router-dom";

import MainBanner from './MainBanner'

const Layout = () => {
  return (
    <>
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark navbarScroll">
        <div className="container">
            <a className="navbar-brand" href="/">Vertex Media Technologies</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    {/* <li className="nav-item active" to="/">
                        <Link className="nav-link" to="/" exact>Home</Link>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link" href="/#products">Products</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#service">Services</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#features">Features</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#technologies">Technologies</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#career">Career</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#about">About</a>
                    </li>
                </ul>          
            </div>
        </div>
    </nav>
      <MainBanner/> 
      <Outlet />
    </>
  )
};

export default function NavBar() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/robots.txt" element={<Robots/>} />
            <Route path="/Sitemap.xml" element={<Sitemap/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  