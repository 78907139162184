import ReactDOM from "react-dom/client";
import Header from './components/Header'
import App from "./App"
import Footer from './components/Footer'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

const footer = ReactDOM.createRoot(document.getElementById('footer'));
footer.render(<Footer />);

