const NoPage = () => {
    return <div className="text-center"><h1>PAGE NOT FOUND</h1>
    <div><p>Sorry, but the page you are looking for might have been removed, had its name changed or is temporarily unavailable</p>
 
    <p>Please try the following:</p>
    
    <p>If you type the page address in the Address bar, make sure that it is spelled correctly.</p>
    <p>Click the Back button to return to your previously visited page</p>
    <p>If you were linked to this page, contact us and make our team aware of this issue.</p></div>
    <br></br>
    <a href="/">Return to homepage</a>
    </div>
  };
  
  export default NoPage;