import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const About = () => {
  return (
    <section id="about">
        <div className="container mt-4 pt-4">
        <br></br><h1 className="text-center">About Us</h1><br></br>
            <div className="row mt-4">
    

                <div className="col-lg-8">
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <p><b>Vertex Media Technologies</b><br></br>
                            Serving Content Writing, Media, Technologies, and Engineering</p>
                        </div>
                        <div className="col-md-6">
                            <p>
                            <i className="fa fa-phone"></i> +977 9862441163<br></br>
                                <i className="fa fa-envelope-o"></i> vertexnepalservices@gmail.com<br></br>
                                <i className="fa fa-registered"></i> Kathmandu-06, Nepal (No. 1939/078/079)
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <p>We provide content writing services, custom software development, IT outsourcing services and along with other engineering works. Our developers build custom software for individuals, startups, and small to medium business.
Along this, we work on content writing, design and engineering with civil engineer, interior designer and architect. We helps in solving complex business challenges using thought process and technology solutions.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <img src={require("../images/about.jpg")} className= "imageAboutPage" alt=""/>
                </div>
            </div>
        </div>
        <br></br>
    </section>
  )
}

export default About