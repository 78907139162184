import React from 'react'
import "../styles/style.css"
import NavBar from '../components/NavBar'
import MainBanner from '../components/MainBanner'


const App = () => {
  return (
    <div>
        <NavBar/>
        <MainBanner/>
    </div>
  )
}

export default App