import React from 'react'

const MainBanner = () => {
  return (
    <div className='banner-container'>
     <section className="bgimage" id="banner">
        <div className="container-fluid">
          <h1 className='hero-text'>Business, Media, Technologies and Engineering</h1>
        </div>
    </section>
    </div>
  )
}

export default MainBanner