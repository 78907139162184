import { Label } from '@mui/icons-material'
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Products = () => {
  return (
    <section id="products">
            <div className="container mt-4 pt-4">
           <br></br><h1 className="text-center">Feature Products</h1><br></br>
            <div className="row mt-4 text-center">
            <div className="col-lg-3 products-image-wrapper">
              <br/>
              <br/>
                    <img src={require("../images/restaurant-pos.jpg")}  style={{ width: '75%', height: '75%' }} className= "imageAboutPage" alt=""/>
                    <br/>
                    <p className='text-center'><h5>Restaurant POS Software</h5>(With Automatic KOT)</p>
                </div>
                 <div className="col-lg-3 products-image-wrapper">
                  <br/>
                  <br/>
                    <img src={require("../images/seo.gif")} className= "imageAboutPage" alt=""/>
                    <br/><br/>
                    <p className='text-center'><h5>SEO and SEM</h5></p>
                </div>
                <div className="col-lg-3 products-image-wrapper">
                    <img src={require("../images/pay.gif")} className= "imageAboutPage" alt=""/> 
                    <br/>
                    <p className='text-center'><h5>Content Writing</h5></p>            
                </div>
                <div className="col-lg-3 products-image-wrapper">
                    <img src={require("../images/software-development.gif")} className= "imageAboutPage" alt=""/>
                    <br/>
                    <p className='text-center'><h5>Portfolio Website</h5></p>   
                </div>
            </div>




        </div>
    </section>
  )
}

export default Products