import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Services = () => {
  return (
    <section id="service">
        <div className="container mt-4 pt-4">
        <h1 className="text-center">Services</h1><br></br>
            <div className="row mt-4">
                 <div className="col-lg-4">
                    <p className='text-center'> <h2>Software/Web Development</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Translate unique client requirements into custom worldclass solutions with premier quality and advanced technologies.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                <p className='text-center'><h2>Mobile App Development</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Achieve measurable business results by developing custom scalable and featured-rich mobile application.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                 <p className='text-center'> <h2>Civil Engineering</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Fulfil your engineering work with our engineers mainly on design and analysis using advance solutions.
                        </p>
                    </div>
                </div>
            </div>


            <div className="row mt-4">
                 <div className="col-lg-4">
                    <p className='text-center'> <h2>Ecommerce Solutions</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>We Provide a complete Ecommerce solution from Product research to launch product and growing your business.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                <p className='text-center'><h2>Software Testing & QA</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Our team incorporates quality assurance and testing activities to ensure high-end application performance and bug-free functioning.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                 <p className='text-center'> <h2>Offshore Development</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Pace up with the changing dynamism of the software market for streamlined access to the best resources, technology, and cost.
                        </p>
                    </div>
                </div>
            </div>


            <div className="row mt-4">
                 <div className="col-lg-4">
                    <p className='text-center'> <h2>IT Consulting</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Our team covers all IT strategies and provides IT consulting services that help optimize your business and make it grow.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                <p className='text-center'><h2>CMS Solutions</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>We are a team of technical experts for CMS theme customization, module development and third party integration to address your unique business needs.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                 <p className='text-center'> <h2>UI/UX Design</h2>                    
                    </p>
                    <div className="row mt-3">
                        <p>Our UI/UX Designer and expert software developer team to build software solutions and helps to grow your business in digital world.
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </section>
  )
}

export default Services