import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Technologies = () => {
  return (
    <section id="technologies">
           <div className="container mt-4 pt-4">
           <br></br><h1 className="text-center">Our Technologies</h1><br></br>
            <div className="row mt-4">
                 <div className="col-lg-2">
                 <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/.netframework.png")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>.NET FRAMEWORK
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/.netcore.png")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>.NET Core
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/autocad.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>AUTOCAD
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/episerver.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>EPI SERVER
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/postgresql.png")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>POSTGRE SQL
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/react.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>REACT JS
                        </p>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                 <div className="col-lg-2">
                 <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/wordpress.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>WORDPRESS
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/kentico.png")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>KENTICO
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/mssql.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>MS SQL
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/msoffice.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>MS OFFICE
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/vuejs.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>VUE JS
                        </p>
                    </div>
                </div>
                <div className="col-lg-2">
                <div className="row mt-3">
                    <p className='text-center technologies-image-wrapper'> <img src={require("../images/Technologies/aws.jpg")} className= "imageAboutPage" alt=""/>                 
                    </p>
                    </div>
                    <div className="row mt-3">
                        <p className='text-center'>AWS
                        </p>
                    </div>
                </div>
            </div>




        </div>
    </section>
  )
}

export default Technologies