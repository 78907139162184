// import React from 'react'
import "./styles/style.css"
import NavBar from './components/NavBar'

// const App = () => {
//   return (
//     <div>
//         <NavBar/>
//     </div>
//   )
// }

// export default App

import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

export default function FloatingActionButtonExtendedSize() {
  return (
    <div>
     <NavBar/>
    <Box sx={{ '& > :not(style)': { m: 1 } }} className="fab-container">
      <Fab variant="extended" size="small" color="primary" aria-label="add" href="#banner">
        <NavigationIcon sx={{ mr: 1 }} />
        Go to Top
      </Fab>
    </Box>
    </div>
  );
}