import { toBeRequired } from '@testing-library/jest-dom/dist/matchers'
import React from 'react'

const Career = () => {
  return (
    <section id="career">
           <div className="container mt-4 pt-4">
           <br></br><h1 className="text-center">Career</h1><br></br>
            <div className="row mt-4 text-center">
                <p>If you are looking for internship or employment opportunity , then do not hesitate to send your resume along with cover letter at <a href="mailto:vertexnepalservices@gmail.com">vertexnepalservices@gmail.com</a></p>
                <p>We don't let any talent go away unnoticed.</p><br></br>
                <p className="quotes">"Equal Opportunity Employer: Race, Color, Religion, Sex, Sexual Orientation, Gender Identity, National Origin, Age, Genetic Information, Disability, Protected Veteran Status, or any other legally protected group status."</p>
            </div>
        </div>
    </section>
  )
}

export default Career