import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(
  name: string,
  data: string
) {
  return { name, data };
}

const rows = [createData('','')];

const Verify = () => {
    const[DocumentNo,setDocumentNo]=React.useState(' ')
    const[showResult,setShowResult]=React.useState(false)
    const[haveRecord,setHaveRecord]=React.useState(true)
 

    const Submit=()=>{
        if(DocumentNo=='')return;
        rows.splice(0,rows.length);
        if(DocumentNo=='CIVIL-CONS-00526'){
        rows.push(createData('Document Holder', 'Laxmi Panta'));
        rows.push(createData('Document Name', 'Property Valuation Report'));
        rows.push(createData('Other Document Information', 'Lalpurja/ Land/Building Ownership'));
        rows.push(createData('Issued On', 'April 09,2023'));
        rows.push(createData('Signed By', 'Er. Shantiram Wagle'));
        setHaveRecord(true);
        setShowResult(true);
        }else{
          setDocumentNo(' ');
          setHaveRecord(false);
          setShowResult(false);
        }
   
    }

    const Reset=()=>{
      // setDocumentNo('');
      setShowResult(false);
    }

    return <section>
      <div className="container mt-4 pt-4">
        <h1 className="text-center">Please enter document number to verify</h1>
        <div className="row mt-4">

          <div className="col-lg-12 text-center">
           <TextField id="standard-basic" onChange={(event)=>setDocumentNo(event.target.value)} label="Document No." variant="standard" />
           <Button variant="contained" onClick={()=>Submit()}>Submit</Button>&nbsp;
           <Button variant="outlined" onClick={()=>Reset()}>Reset</Button>
           <br></br>
           <p hidden={haveRecord}>No record(s) found</p>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" hidden = {!showResult}>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </div>
        
        </div>
      </div>
      </section>;
  };
  
export default Verify;
