import React from 'react'
import "../styles/style.css"
import Products from '../components/Products'
import Quotes from '../components/Quotes'
import Services from '../components/Services'
import Features from '../components/Features'
import Technologies from '../components/Technologies'
import Career from '../components/Career'
import About from '../components/About'


const Home = () => {
  return (
    <div>
        <Products/>
        <Quotes/>
        <Services/>
        <Features/>
        <Technologies/>
        <Career/>
        <About/>
    </div>
  )
}

export default Home