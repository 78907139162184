import React from 'react'

const Footer = () => {
  return (
    <footer id="footer">
    <div className="container-fluid">
        <div className="social-icons mt-4">
            <a href="#" target="_blank"><i className="fab fa-facebook"></i></a>&nbsp;
            <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>&nbsp;
            <a href="#" target="_blank"><i className="fab fa-twitter"></i></a>
        </div>
        © Copyright 2024 @ Vertex Media Technologies. All Rights Reserved.<br></br>&nbsp;
    </div>
</footer>
  )
}

export default Footer